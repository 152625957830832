/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useState } from 'react'

import './SubscribeForm.css'

const SubscribeForm = () => {
  const [emailValue, setEmailValue] = useState('')

  const handleInputChange = e => {
    setEmailValue(e.target.value)
  }

  const handleFormSubmit = e => {
    e.preventDefault()
    window.location = `https://tech.us7.list-manage.com/subscribe?u=205c8eec2ba5c6150d1f89cf3&id=1225e967b0&MERGE0=${emailValue}`
  }

  return (
    <form
      className="SubscribeForm"
      onSubmit={handleFormSubmit}
    >
      <input
        onChange={handleInputChange}
        placeholder="Email Address"
        type="email"
        value={emailValue}
      />
      <button type="submit">Subscribe</button>
    </form>
  )
}

export default SubscribeForm
