import React from 'react';
import { Link } from 'gatsby'

import './Logo.css'

const Logo = () => (
  <Link to="/">
    <svg className="Logo" width="200" height="28" viewBox="0 0 200 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M51.1693 5.61236L46.7192 18.8422H46.6471L42.1489 5.61236H36.448V23.172H39.8878V9.50916H40.0081L44.867 23.172H48.2587L53.0215 9.50916H53.1177V23.172H56.7258V5.61236H51.1693Z" fill="white" className="text" />
      <path d="M66.0914 10.0624C61.8819 10.0624 59.1638 12.8768 59.1638 16.9419C59.1638 20.8387 61.7616 23.4607 65.9952 23.4607C69.0982 23.4607 71.7923 22.1617 72.8747 19.1309L69.9401 18.2168C69.459 19.9968 67.9676 20.7906 66.2117 20.7906C63.8544 20.7906 62.8922 19.3714 62.7238 17.3749L72.8747 17.399C72.9709 12.6362 70.1566 10.0624 66.0914 10.0624ZM66.0914 12.6603C67.703 12.6603 68.8336 13.4781 69.1944 15.2341H62.8441C63.253 13.4541 64.4557 12.6603 66.0914 12.6603Z" fill="white" className="text" />
      <path d="M75.2046 23.172H78.8128V5.61236H75.2046V23.172Z" fill="white" className="text" />
      <path d="M94.9598 20.6463C94.1419 20.6463 94.1419 20.0449 94.1419 19.4676V15.1379C94.1419 11.9386 92.1214 10.0624 88.2486 10.0624C85.2899 10.0624 82.7642 11.0727 81.4413 14.3441L84.2797 15.4987C85.1937 13.2616 86.5648 12.6603 87.984 12.6603C89.3311 12.6603 90.4857 13.2857 90.6059 14.6808C88.0081 15.4987 85.6026 16.1 84.6645 16.4368C82.3794 17.2306 81.1767 18.0725 81.1767 19.9006C81.1767 21.8731 82.9326 23.4607 85.5545 23.4607C87.984 23.4607 89.74 22.1377 90.63 20.6223H90.7503V21.1033C90.7503 22.6669 91.6643 23.4126 93.5887 23.4126C94.4787 23.4126 95.3928 23.148 95.946 22.9315V20.5501C95.6333 20.6223 95.2244 20.6463 94.9598 20.6463ZM86.6851 20.7425C85.4583 20.7425 84.8329 20.0931 84.8329 19.4195C84.8329 18.8663 85.1937 18.6257 86.3243 18.2168C86.9737 17.9763 89.3311 17.1825 90.6059 16.7495V18.6739C90.197 19.636 88.6816 20.7425 86.6851 20.7425Z" fill="white" className="text" />
      <path d="M106.337 10.0624C104.004 10.0624 102.344 11.1449 101.599 13.3097H101.502V10.3511H97.9904V23.172H101.599V16.2925C101.599 14.1276 102.922 12.973 104.822 12.973C106.53 12.973 107.42 13.9111 107.42 15.6671V23.172H111.028V15.186C111.028 11.8665 109.344 10.0624 106.337 10.0624Z" fill="white" className="text" />
      <path d="M113.986 5.61236V8.71537H117.691V5.61236H113.986ZM114.034 23.172H117.643V10.3511H114.034V23.172Z" fill="white" className="text" />
      <path d="M129.123 10.0624C126.79 10.0624 125.13 11.1449 124.384 13.3097H124.288V10.3511H120.776V23.172H124.384V16.2925C124.384 14.1276 125.707 12.973 127.608 12.973C129.316 12.973 130.206 13.9111 130.206 15.6671V23.172H133.814V15.186C133.814 11.8665 132.13 10.0624 129.123 10.0624Z" fill="white" className="text" />
      <path d="M136.7 19.203V23.172H140.669V19.203H136.7Z" fill="white" className="text" />
      <path d="M156.116 5.61236H139.566V8.71537H146.037V23.172H149.645V8.71537H156.116V5.61236Z" fill="white" className="text" />
      <path d="M161.691 10.0624C157.482 10.0624 154.764 12.8768 154.764 16.9419C154.764 20.8387 157.362 23.4607 161.595 23.4607C164.698 23.4607 167.392 22.1617 168.475 19.1309L165.54 18.2168C165.059 19.9968 163.568 20.7906 161.812 20.7906C159.454 20.7906 158.492 19.3714 158.324 17.3749L168.475 17.399C168.571 12.6362 165.757 10.0624 161.691 10.0624ZM161.691 12.6603C163.303 12.6603 164.434 13.4781 164.794 15.2341H158.444C158.853 13.4541 160.056 12.6603 161.691 12.6603Z" fill="white" className="text" />
      <path d="M184.058 17.6876L180.883 17.2546C180.595 19.2752 179.464 20.7906 177.131 20.7906C174.966 20.7906 173.643 19.5158 173.643 16.7976C173.643 14.0073 175.11 12.7324 177.083 12.7324C179.151 12.7324 180.282 14.0554 180.715 15.8595L183.674 15.0898C183.048 11.8184 180.522 10.0624 177.011 10.0624C173.138 10.0624 169.939 12.6843 169.939 16.9419C169.939 20.8147 172.633 23.4607 176.842 23.4607C181.028 23.4607 183.505 21.2717 184.058 17.6876Z" fill="white" className="text" />
      <path d="M194.444 10.0624C191.99 10.0624 190.379 11.2411 189.777 13.1414H189.705V5.61236H186.097V23.172H189.705V16.3165C189.705 14.1516 191.028 12.973 192.928 12.973C194.636 12.973 195.526 13.9111 195.526 15.6671V23.172H199.134V15.186C199.134 11.8665 197.451 10.0624 194.444 10.0624Z" fill="white" className="text" />
      <path fillRule="evenodd" clipRule="evenodd" d="M25.2406 16.9298L25.2406 16.9297C29.0011 13.1692 29.0011 7.07214 25.2405 3.31159C21.48 -0.448966 15.3829 -0.448968 11.6223 3.31159L11.5956 3.33839L3.86424 11.0698L3.81655 11.1171C0.0559931 14.8777 0.0559943 20.9748 3.81656 24.7353C7.57712 28.4959 13.6742 28.4959 17.4347 24.7353L17.4349 24.7352L17.4351 24.7353L25.2406 16.9298ZM23.9115 15.6012C26.9383 12.5744 26.9383 7.66704 23.9115 4.64024C20.8847 1.61345 15.9773 1.61345 12.9505 4.64024C9.92369 7.66703 9.9237 12.5744 12.9505 15.6012C15.9773 18.628 20.8847 18.628 23.9115 15.6012Z" fill="url(#paint0_linear)" />
      <defs>
        <linearGradient id="paint0_linear" x1="1.42224" y1="14" x2="28.5123" y2="15.5474" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FDE7E7" />
          <stop offset="0.161458" stopColor="#FEEBDD" />
          <stop offset="0.328125" stopColor="#FFFAE5" />
          <stop offset="0.510417" stopColor="#E8FDF3" />
          <stop offset="0.671875" stopColor="#E8FBFD" />
          <stop offset="0.822917" stopColor="#F1F1FE" />
          <stop offset="1" stopColor="#FEF1FB" />
        </linearGradient>
      </defs>
    </svg>
  </Link>
)

export default Logo
