import React, { useState } from "react"
import PropTypes from "prop-types"
import { graphql, Link, useStaticQuery } from "gatsby"

import Button from '../Button/Button'
import Logo from '../Logo/Logo'
import LinkWithChevron from '../LinkWithChevron/LinkWithChevron'

import './Header.css'

const Header = ({
  siteTitle = '',
}) => {
  const { site: { siteMetadata: {
    donateLink,
    membershipLink,
    storeLink,
  } } } = useStaticQuery(graphql`
    query HeaderQuery {
      site {
        siteMetadata {
          donateLink
          membershipLink
          storeLink
        }
      }
    }
  `);

  const [showMobileMenu, setShowMobileMenu] = useState(false)

  const toggleMobileMenu = () => {
    if (showMobileMenu) {
      document.body.style.overflow = 'auto'
    } else {
      document.body.style.overflow = 'hidden'
    }
    setShowMobileMenu(!showMobileMenu)
  }

  return (
    <header id="Header">
      <div className="Header--menu">
        <Logo />
        <div className={`Header--menu-options${showMobileMenu ? ' is-visible' : ''}`}>
          <Link to="/about">About</Link>
          <Link to="/resources">Resources</Link>
          <Link to="/membership">Membership</Link>
          <a href={storeLink}>Store</a>
          <LinkWithChevron href={donateLink} target="_blank">Donate</LinkWithChevron>
          <Button className="Header--join-button" href={membershipLink} target="_blank">Become a Member</Button>
        </div>
        <button
          aria-label="Toggle Menu"
          className={`Header--mobile-toggle${showMobileMenu ? ' is-active' : ''}`}
          onClick={toggleMobileMenu}
          title="Toggle Menu"
        />
      </div>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

export default Header
