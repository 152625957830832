import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'

import Footer from '../Footer/Footer'
import Header from '../Header/Header'
import MembershipCallout from '../MembershipCallout/MembershipCallout'

import './GlobalCSSVariables.css'
import './Layout.css'

const Layout = ({
  children,
  className,
  includeMembershipDescription = false,
  linkMembershipToMobilize = false,
}) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <div id="Layout" className={className}>
      <Header siteTitle={data.site.siteMetadata.title} />
      <main>
        {children}
        <div className="Layout--rainbow" />
      </main>
      <MembershipCallout
        includeDescription={includeMembershipDescription}
        linkToMobilize={linkMembershipToMobilize}
      />
      <Footer />
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
