import React from 'react'
import { Link } from 'gatsby'

import './Button.css'

const Button = ({ children, className, href, target }) => (
  <>
    {href.includes('//') ?
      (
        <a className={`Button ${className ? className : ''}`} href={href} target={target}>{children}</a>
      ) :
      (
        <Link className={`Button ${className ? className : ''}`} to={href} target={target}>{children}</Link>
      )
    }
  </>
)

export default Button
