import React from 'react'
import { graphql, Link, useStaticQuery } from 'gatsby'

import Container from '../Container/Container'
import LinkWithChevron from '../LinkWithChevron/LinkWithChevron'
import Logo from '../Logo/Logo'
import SubscribeForm from '../SubscribeForm/SubscribeForm'

import './Footer.css'

import FacebookSVG from '../../images/SocialIcons/Facebook.svg'
import InstagramSVG from '../../images/SocialIcons/Instagram.svg'
import TwitterSVG from '../../images/SocialIcons/Twitter.svg'

const Footer = () => {
  const { site: { siteMetadata: {
    donateLink,
    facebookLink,
    instagramLink,
    storeLink,
    twitterLink,
  } } } = useStaticQuery(graphql`
    query FooterQuery {
      site {
        siteMetadata {
          donateLink
          facebookLink
          instagramLink
          storeLink
          twitterLink
        }
      }
    }
  `);
  return (
    <footer id="Footer">
      <Container>
        <div className="Footer--subscribe">
          <Logo />
          <h4>Receive updates, event info, and news from Melanin.Tech</h4>
          <SubscribeForm />
          <p className="font-small">Or <a href={donateLink}>Donate</a> to help us on our mission to connect Black people in tech all over the world.</p>
        </div>
        <div className="Footer--menu">
          <Link to="/about">About</Link>
          <Link to="/resources">Resources</Link>
          <a href="/membership">Membership</a>
          <a href={storeLink}>Store</a>
          <LinkWithChevron href={donateLink} target="_blank">Donate</LinkWithChevron>
        </div>
        <div className="Footer--meta">
          <div className="Footer--social-icons">
            <a href={instagramLink}>
              <img src={InstagramSVG} alt="Follow us on Instagram" />
            </a>
            <a href={facebookLink}>
              <img src={FacebookSVG} alt="Follow us on Facebook" />
            </a>
            <a href={twitterLink}>
              <img src={TwitterSVG} alt="Follow us on Twitter" />
            </a>
          </div>
          <div>
            <span className="Footer--tagline">Connect with Black people in tech from all over the world</span>
            <span className="Footer--copyright">&copy;{new Date().getFullYear()} Melanin.Tech</span>
          </div>
        </div>
      </Container>
    </footer>
  )
}

export default Footer