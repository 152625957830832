import React from 'react';

import Button from '../Button/Button'

import './MembershipCallout.css'

const MembershipCallout = ({
  includeDescription = false,
  linkToMobilize = false,
}) => (
    <section className={`MembershipCallout${includeDescription ? ' includes-description': ''}`}>
      <div className="MembershipCallout--box">
        <div className="MembershipCallout--content">
          <h2>We’re here to help you connect. Get involved.</h2>
          {includeDescription && <p>We are here to cultivate a close-knit community and provide a safe space for people who either work in the tech industry or aspire to do so.</p>}
        </div>
        <Button
          href={linkToMobilize ? 'https://melanintech.mobilize.io/registrations/groups/43594' : '/membership'}
          target={linkToMobilize ? '_blank' : undefined}
        >
          {includeDescription ?
            'Sign Up to Become a Member' :
            'Become a Member'
          }
        </Button>
      </div>
    </section>
  )

export default MembershipCallout
