import React from 'react';

import ChevronIconSVG from '../../images/ChevronIcon.svg'

import './LinkWithChevron.css'

const LinkWithChevron = ({
  children,
  className,
  href,
  ...props
}) => (
    <a
      href={href}
      className={`LinkWithChevron ${className ? className : ''}`}
      {...props}
    >
      {children}<img src={ChevronIconSVG} alt="" role="presentation" />
    </a>
  )

export default LinkWithChevron
